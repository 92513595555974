var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "value-contents",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('icon-rounded-object', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "grey--text text--lighten-1"
    }
  }), _c('div', {
    staticClass: "value-contents__btn-group mb-20px mb-md-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn-prev",
    attrs: {
      "prev": "",
      "color": "grey lighten-1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn-next",
    attrs: {
      "next": "",
      "color": "grey lighten-1"
    }
  })], 1)], 1)], 1), _c('swiper', {
    ref: "swiperValue",
    attrs: {
      "options": _vm.swiperOptionValue
    }
  }, _vm._l(_vm.slide, function (item, index) {
    return _c('swiper-slide', {
      key: index,
      staticClass: "swiper swiper--value"
    }, [_c('div', {
      staticClass: "value-contents__slide w-100"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "value-contents__right",
      attrs: {
        "cols": "12",
        "md": "8",
        "offset-md": "4"
      }
    }, [_c('div', {
      staticClass: "value-contents__right__inner"
    }, [_c('div', {
      staticClass: "value-contents__right__image-wrap"
    }, [_c('div', {
      staticClass: "value-contents__right__image"
    }, [_c('div', {
      staticClass: "value-contents__right__image__inner image-card",
      style: 'background-image: url(' + item.image + ')'
    })])])])]), _c('v-col', {
      staticClass: "value-contents__left",
      attrs: {
        "cols": "12",
        "md": "10",
        "lg": "7"
      }
    }, [_c('div', {
      staticClass: "value-contents__left__inner"
    }, [_c('v-card', {
      staticClass: "value-contents__left__card",
      attrs: {
        "rounded": "",
        "dark": "",
        "color": "grey darken-3"
      }
    }, [_c('div', {
      staticClass: "pa-20px pa-md-30px pa-lg-60px"
    }, [_c('txt', {
      staticClass: "white--text font-weight-bold line-height-1 font-secondary"
    }, [_vm._v(" Value 0" + _vm._s(index + 1) + " ")]), _c('span', {
      staticClass: "line my-12px my-md-16px"
    }), _c('tit', {
      staticClass: "white--text"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.txt)
      }
    })])], 1)])], 1)])], 1)], 1)]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }