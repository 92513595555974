<template>
    <v-card v-bind="$attrs" tile class="v-card--none-active-bg project-card" :class="{ 'project-card--link': !masonry }">
        <v-card rounded="sm" class="project-card__thumb v-card--none-active-bg">
            <div v-if="masonry" class="image-wrap"><img :src="image" /></div>
            <v-img v-else :src="image" max-width="342" :aspect-ratio="1 / 1" class="w-100" />
            <btn-arrow-secondary v-if="!masonry" color="white" class="project-card__btn" />
        </v-card>
        <div class="pt-16px pt-md-32px">
            <tit class="tit--sm text-truncate-2"><slot /></tit>
            <txt class="text-truncate-2 txt--dark mt-4px mt-md-8px"><slot name="TxtAdd" /></txt>
            <btn-primary v-if="!masonry" class="v-btn--none-active-bg mt-md-16px">View More</btn-primary>
        </div>
    </v-card>
</template>

<script>
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    props: {
        masonry: { type: Boolean, default: false },
        image: { type: String, default: "" },
    },
    components: {
        BtnPrimary,
        BtnArrowSecondary,
        Tit,
        Txt,
    },
}
</script>

<style lang="scss" scoped>
.project-card{
    display: block;
    &__thumb{
        position: relative;
        overflow: hidden;
    }
    &__btn{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.4);
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-out;
    }    
    &--link .project-card__thumb::after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        transition: 0.2s ease-out;
    }
}
.image-wrap{
    position: relative;
    >img{
        display: block;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .masonry-grid-item {
        width: 33.3333%;
    }
}
@media (min-width: 1024px) {
    .project-card{
        &:hover{
            .project-card__thumb::after{
                opacity: 1;
                visibility: visible;
                transition: 0.2s ease-out;
            }
            .project-card__btn{
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%) scale(1);
                transition: 0.2s 0.2s ease-out;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>