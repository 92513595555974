<template>
    <v-card v-bind="$attrs" rounded="sm" class="story-card transparent">
        <v-responsive max-width="446" :aspect-ratio="1 / 1" class="story-card__image image-card" :style="`background-image: url(`+image+`)`"></v-responsive>
        <div class="story-card__contents pa-16px pa-md-20px pa-lg-40px pb-2px pb-md-6px pb-lg-26px">
            <div class="w-100">
                <tit class="white--text tit--sm text-truncate"><slot /></tit>
                <txt class="white--text text-truncate mt-4px mt-md-8px"><slot name="TxtAdd" /></txt>
                <btn-primary class="v-btn--none-active-bg mt-md-16px">View More</btn-primary>
            </div>
        </div>
    </v-card>
</template>

<script>
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    props: {
        masonry: { type: Boolean, default: false },
        image: { type: String, default: "" },
    },
    components: {
        BtnPrimary,
        BtnArrowSecondary,
        Tit,
        Txt,
    },
}
</script>

<style lang="scss" scoped>
.story-card{
    overflow: hidden;
    &__contents{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, .8), transparent);
        display: flex;
        align-items: flex-end;
    }
    &__image{
        transform: scale(1);
        transition: 0.25s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .story-card:hover{
        .story-card__image{
            transform: scale(1.2);
        }
    }
}
@media (min-width:1200px){
}
</style>