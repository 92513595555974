<template>
    <v-tab v-bind="$attrs" class="v-tab--inline">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tab>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tab--inline {
    &.v-tab{
        &::before{
            display: none;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: 0;
            background: var(--v-primary-base); 
            transition: 0.2s;
            transform: scaleX(0);
            opacity: 0;
            visibility: hidden;
        }
        &:last-child{
            margin-right: 0 !important;
        }
        &:not(.v-tab--active){
            color: #fff !important;
        }
        &--active {
            color: var(--v-primary-base) !important;
            &::after{
                transform: scaleX(1);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-tab--inline {
        &.v-tab{
            flex: unset;
            align-items: flex-start;
            &--active {
                background-size: 100% 29px;
                background-position: center top;
            }
        }
    }
}
@media (min-width:1024px){
    .v-tab--inline {
        &.v-tab{
            &:hover{
                &::after{
                    transform: scaleX(1);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

</style>
