var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('video', {
    staticClass: "main-visual__video",
    attrs: {
      "src": "/images/main/visual.mp4",
      "poster": "/images/main/visual.jpg",
      "autoplay": "",
      "loop": "",
      "muted": "",
      "playsinline": ""
    },
    domProps: {
      "muted": true
    }
  }), _c('div', {
    staticClass: "main-visual__inner"
  }, [_c('v-container', [_c('txt', {
    staticClass: "main-visual__txt-ani white--text line-height-1 font-secondary"
  }, [_c('span', [_vm._v("L")]), _c('span', [_vm._v("A")]), _c('span', [_vm._v("W")]), _c('span', [_vm._v("F")]), _c('span', [_vm._v("I")]), _c('span', [_vm._v("R")]), _c('span', [_vm._v("M")]), _c('span', {
    staticStyle: {
      "width": "4px"
    }
  }), _c('span', [_vm._v("T")]), _c('span', [_vm._v("A")]), _c('span', [_vm._v("E")]), _c('span', [_vm._v("S")]), _c('span', [_vm._v("U")]), _c('span', [_vm._v("N")]), _c('span', [_vm._v("G")])]), _c('h2', {
    staticClass: "white--text font-size-54 font-size-md-80 font-size-lg-100",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-duration": "600"
    }
  }, [_vm._v("법무법인 태성")]), _c('v-divider', {
    staticClass: "main-visual__line my-20px my-md-40px"
  }), _c('tit', {
    staticClass: "white--text",
    attrs: {
      "data-aos": "fade",
      "data-aos-duration": "800",
      "data-aos-delay": "1200"
    }
  }, [_c('span', {
    staticClass: "font-weight-regular"
  }, [_vm._v("충분한 공감과 소통을 통하여")]), _vm._v(" "), _c('br'), _vm._v(" 맞춤형 법률 서비스를 제공해 드리겠습니다 ")])], 1)], 1), _c('u-scroll-ani')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }