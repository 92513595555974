var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "v-card--none-active-bg project-card",
    class: {
      'project-card--link': !_vm.masonry
    },
    attrs: {
      "tile": ""
    }
  }, 'v-card', _vm.$attrs, false), [_c('v-card', {
    staticClass: "project-card__thumb v-card--none-active-bg",
    attrs: {
      "rounded": "sm"
    }
  }, [_vm.masonry ? _c('div', {
    staticClass: "image-wrap"
  }, [_c('img', {
    attrs: {
      "src": _vm.image
    }
  })]) : _c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.image,
      "max-width": "342",
      "aspect-ratio": 1 / 1
    }
  }), !_vm.masonry ? _c('btn-arrow-secondary', {
    staticClass: "project-card__btn",
    attrs: {
      "color": "white"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "pt-16px pt-md-32px"
  }, [_c('tit', {
    staticClass: "tit--sm text-truncate-2"
  }, [_vm._t("default")], 2), _c('txt', {
    staticClass: "text-truncate-2 txt--dark mt-4px mt-md-8px"
  }, [_vm._t("TxtAdd")], 2), !_vm.masonry ? _c('btn-primary', {
    staticClass: "v-btn--none-active-bg mt-md-16px"
  }, [_vm._v("View More")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }