var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'sub-tab' ? _c('div', {
    class: _vm.className
  }, [_c('v-container', [_vm._l(_vm.gnbs, function (item) {
    return [item.title == _vm.sh ? _c('tabs-primary', {
      key: item.title,
      attrs: {
        "hide-slider": ""
      }
    }, _vm._l(item.children, function (child) {
      return _c('tab-primary', {
        key: child.title,
        class: {
          'v-tab--active': _vm.tabActive == child.title
        },
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))]);
    }), 1) : _vm._e()];
  })], 2)], 1) : _vm.className == 'gnb' ? _c('ul', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [!item.children ? _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('div', {
      class: _vm.className + '__link gnb__link--toggle d-xl-none'
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('router-link', {
      class: _vm.className + '__link gnb__link--toggle d-none d-xl-flex',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('ul', {
      class: _vm.className + '__sub'
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('router-link', {
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 0)], 1)];
  })], 2) : _vm.className == 'slide-gnb' ? _c('ul', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item, index) {
    return [_c('li', {
      key: index,
      class: _vm.className + '__li'
    }, [_c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('div', {
      class: _vm.className + '__num'
    }, [_c('v-img', {
      class: _vm.className + '__num-no-active',
      attrs: {
        "src": '/images/common/0' + (index + 1) + '.png',
        "height": "32",
        "width": "46",
        "contain": ""
      }
    }), _c('v-img', {
      class: _vm.className + '__num-active',
      attrs: {
        "src": '/images/common/0' + (index + 1) + '-active.png',
        "height": "32",
        "width": "46",
        "contain": ""
      }
    })], 1), _c('h2', [_vm._v(_vm._s(item.title))])]), item.children ? _c('ul', {
      class: _vm.className + '__sub'
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('router-link', {
        class: _vm.className + '__sub-link',
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 0) : _vm._e()], 1)];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }