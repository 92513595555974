import Vue from "vue";
import Vuetify from "vuetify";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#FAF3EC",
                lighten4: "#F3E1D0",
                lighten3: "#EBCEB0",
                lighten2: "#E2BA90",
                lighten1: "#DCAB79",
                base: "#D69C61",
                darken1: "#D19459",
                darken2: "#CC8A4F",
                darken3: "#C68045",
                darken4: "#BC6E33",
            },
            secondary: {
                lighten5: "#E3E6E7",
                lighten4: "#B9BFC4",
                lighten3: "#8A959D",
                lighten2: "#5B6B75",
                lighten1: "#374B58",
                base: "#142B3A",
                darken1: "#122634",
                darken2: "#0E202C",
                darken3: "#0B1A25",
                darken4: "#061018",
            },
            accent: {
                lighten5: "#E5ECFF",
                lighten4: "#BFD0FF",
                lighten3: "#94B1FF",
                lighten2: "#6991FF",
                lighten1: "#497AFF",
                base: "#2962FF",
                darken1: "#245AFF",
                darken2: "#1F50FF",
                darken3: "#1946FF",
                darken4: "#0F34FF",
            },
            grey: {
                lighten5: "#F8F6F4",
                lighten4: "#ECECEC",
                lighten3: "#DDD",
                lighten2: "#AAA",
                lighten1: "#999",
                base: "#666",
                darken1: "#555",
                darken2: "#444",
                darken3: "#262524",
                darken4: "#090909",
            },
            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
            info: "#2196F3",
            anchor: "inherit",
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            info: "#2D3539",
            content: "#EDF0F5",
            anchor: "#333",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
