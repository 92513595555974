var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-section', {
    staticClass: "main-section--business",
    scopedSlots: _vm._u([{
      key: "mainSectionImmersive",
      fn: function () {
        return [_c('v-img', {
          staticClass: "business-bg business-bg--2 w-100",
          attrs: {
            "src": "/images/main/business-bg3.svg",
            "aspect-ratio": "1840 / 480"
          }
        }), _c('v-img', {
          staticClass: "business-bg business-bg--3 w-100",
          attrs: {
            "src": "/images/main/business-bg4.svg",
            "aspect-ratio": "1840 / 384"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('tit-wrap-primary', {
    staticClass: "text-center",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Introduction")];
      },
      proxy: true
    }])
  }, [_vm._v(" 사업소개 ")]), _c('main-business')], 1), _c('main-section', {
    staticClass: "main-section--value"
  }, [_c('tit-wrap-primary', {
    staticClass: "main-section--value__title",
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Core Value")];
      },
      proxy: true
    }])
  }, [_vm._v(" 태성의 가치 ")]), _c('main-value')], 1), _c('main-section', {
    staticClass: "main-section--introduction"
  }, [_c('tit-wrap-primary', {
    staticClass: "text-center",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Introduction")];
      },
      proxy: true
    }])
  }, [_vm._v(" 변호사 소개 ")]), _c('main-introduction')], 1), _c('main-section', {
    staticClass: "main-section--project"
  }, [_c('u-tit-wrap-default', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-primary', {
    staticClass: "pb-md-0",
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Project")];
      },
      proxy: true
    }])
  }, [_vm._v(" 주요 프로젝트 ")])], 1)], 1)], 1), _c('main-project')], 1), _c('main-section', {
    staticClass: "main-section--stroy",
    scopedSlots: _vm._u([{
      key: "mainSectionImmersive",
      fn: function () {
        return [_c('v-img', {
          staticClass: "stroy-bg stroy-bg--1 w-100",
          attrs: {
            "src": "/images/main/stroy-bg2.svg",
            "aspect-ratio": "1840 / 480"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Story")];
      },
      proxy: true
    }])
  }, [_vm._v(" 태성소식 ")]), _c('div', {
    staticClass: "d-flex d-md-block justify-end"
  }, [_c('btn-secondary', {
    attrs: {
      "to": "/story",
      "data-aos": "zoom-out",
      "data-aos-delay": "150"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('main-story')], 1)], 1)], 1), _c('main-section', {
    staticClass: "main-section--contact"
  }, [_c('tit-wrap-primary', {
    staticClass: "text-center",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Contact Us")];
      },
      proxy: true
    }])
  }, [_vm._v(" 오시는 길 ")]), _c('main-contact')], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }