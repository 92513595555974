var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "contact-card-group",
    attrs: {
      "align": "start"
    }
  }, _vm._l(_vm.contact, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "contact-card-col",
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "contact-card-wrap",
      attrs: {
        "data-aos": item.aos,
        "data-aos-duration": "700",
        "data-aos-delay": item.aosDelay
      }
    }, [_c('p', {
      staticClass: "contact-card__caption d-none d-md-block font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase"
    }, [_vm._v("Lawfirm Taesng")]), _c('router-link', {
      staticClass: "contact-card",
      attrs: {
        "to": item.link
      }
    }, [_c('div', {
      staticClass: "contact-card__thumb-wrap"
    }, [_c('v-card', {
      staticClass: "contact-card__thumb v-card--none-active-bg transparent",
      attrs: {
        "rounded": "sm"
      }
    }, [_c('v-img', {
      staticClass: "contact-card__thumb__thumb w-100",
      attrs: {
        "src": item.image,
        "max-width": "342",
        "aspect-ratio": 342 / 512
      }
    }), _c('div', {
      staticClass: "contact-card__active pa-16px"
    }, [_c('txt', {
      staticClass: "primary--text line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('tit', {
      staticClass: "white--text tit--sm"
    }, [_vm._v(_vm._s(item.title))]), _c('txt', {
      staticClass: "white--text my-16px my-md-32px"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.location)
      }
    })]), _c('icon-arrow-primary', {
      staticClass: "white--text",
      attrs: {
        "size": "x-large"
      }
    })], 1)], 1)], 1), _c('div', {
      staticClass: "contact-card__contents pt-16px pt-md-32px d-none d-lg-block"
    }, [_c('txt', {
      staticClass: "txt--light line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('tit', {
      staticClass: "white--text tit--sm"
    }, [_vm._v(_vm._s(item.title))])], 1)])], 1)]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }