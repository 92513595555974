var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_c('div', {
    staticClass: "masonry-grid",
    attrs: {
      "data-aos": "fade-up"
    }
  }, _vm._l(_vm.project, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "masonry-grid-item"
    }, [_c('project-item', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": item.aosDelay,
        "masonry": "",
        "image": item.image
      },
      scopedSlots: _vm._u([{
        key: "TxtAdd",
        fn: function () {
          return [_vm._v(_vm._s(item.txt))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }