var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "story-card transparent",
    attrs: {
      "rounded": "sm"
    }
  }, 'v-card', _vm.$attrs, false), [_c('v-responsive', {
    staticClass: "story-card__image image-card",
    style: `background-image: url(` + _vm.image + `)`,
    attrs: {
      "max-width": "446",
      "aspect-ratio": 1 / 1
    }
  }), _c('div', {
    staticClass: "story-card__contents pa-16px pa-md-20px pa-lg-40px pb-2px pb-md-6px pb-lg-26px"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('tit', {
    staticClass: "white--text tit--sm text-truncate"
  }, [_vm._t("default")], 2), _c('txt', {
    staticClass: "white--text text-truncate mt-4px mt-md-8px"
  }, [_vm._t("TxtAdd")], 2), _c('btn-primary', {
    staticClass: "v-btn--none-active-bg mt-md-16px"
  }, [_vm._v("View More")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }