<template>
    <div v-bind="{ loading }">
        <div data-aos="fade-up" class="masonry-grid">
            <div v-for="(item, index) in project" :key="index" class="masonry-grid-item">
                <project-item data-aos="fade-up" :data-aos-delay="item.aosDelay" masonry :image="item.image">
                    {{item.title}}
                    <template #TxtAdd>{{item.txt}}</template>
                </project-item>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectItem from "@/components/client/project/project-item.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ProjectItem,
        Tit,
        Txt,
    },
    data: () => ({
        loading: false,        
        project : [
            {
                title : "래미안크레시티& 전농제7구역주택재개발정비사업조합",
                txt : "세대수 2,397세대 “조합정산&건설엔지니어링 서비스”",                
                image: "/images/main/project-img.jpg",
                aosDelay: 0,
            },
            {
                title : "남산4-4지구재개발정비사업조합",
                txt : "세대수 1,368세대 “정비사업조합 소송대리”",                
                image: "/images/main/project-img2.jpg",
                aosDelay: 100,
            },
            {
                title : "용인e편한세상한숲시티",
                txt : "세대수 6,725 “단체등기&건설엔지니어링서비스”",                
                image: "/images/main/project-img3.jpg",
                aosDelay: 200,
            },
            {
                title : "평촌어바인퍼스트",
                txt : "세대수 3,850세대 “아파트하자관리”",                
                image: "/images/main/project-img4.jpg",
                aosDelay: 100,
            },
            {
                title : "운정신도시아이파크",
                txt : "세대수 3,042세대 “단체등기&건설엔지니어링서비스”",                
                image: "/images/main/project-img5.jpg",
                aosDelay: 200,
            },
            {
                title : "온천2구역주택재개발정비사업조합",
                txt : "세대수 3,853세대 “조합 정산소송”",                
                image: "/images/main/project-img6.jpg",
                aosDelay: 200,
            },
            {
                title : "영통힐스테이트",
                txt : "세대수 2,328세대 “단체등기&하자협상”",                
                image: "/images/main/project-img7.jpg",
                aosDelay: 100,
            },
            {
                title : "성남신흥역하늘채랜더스원",
                txt : "세대수 2,411세대 “단체등기&정비사업자문”",                
                image: "/images/main/project-img8.jpg",
                aosDelay: 200,
            },
            {
                title : "노은한화꿈에그린2단지",
                txt : "세대수 998세대 “아파트하자소송 & 건설엔지니어링 서비스”",                
                image: "/images/main/project-img9.jpg",
                aosDelay: 200,
            },
            {
                title : "염주주공아파트 주택재건축정비사업조합",
                txt : "세대수 1,976세대 “정비사업조합 소송대리”",                
                image: "/images/main/project-img10.jpg",
                aosDelay: 100,
            },
            {
                title : "범지기10단지푸르지오",
                txt : "세대수 1,970세대 “아파트하자소송”",                
                image: "/images/main/project-img11.jpg",
                aosDelay: 200,
            },
            {
                title : "흑석3재정비촉진지역 재개발정비사업조합",
                txt : "세대수 1,772세대 “정비사업조합대리”",                
                image: "/images/main/project-img12.jpg",
                aosDelay: 200,
            },
            {
                title : "화서역파크푸르지오",
                txt : "세대수 2,355세대 “단체등기&법률자문”",                
                image: "/images/main/project-img13.jpg",
                aosDelay: 100,
            },
            {
                title : "천안 한양수자인데코시티",
                txt : "세대수 3,200세대 “단체등기&법률자문”",                
                image: "/images/main/project-img14.jpg",
                aosDelay: 200,
            },
            {
                title : "세종시 마스터힐스",
                txt : "세대수 3,100세대 “단체등기”",                
                image: "/images/main/project-img15.jpg",
                aosDelay: 200,
            },
        ]
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = false;

            
            let mobileWidth = 768;
            let offsetY = 300;
            if (window.innerWidth < mobileWidth) {
                offsetY = 180;
            }

            this.$nextTick(() => {
                setTimeout(() => {
                    var grid = document.querySelector(".masonry-grid");
                    var msnry;

                    imagesLoaded(grid, function() {
                        // init Isotope after all images have loaded
                        msnry = new Masonry(grid, {
                            itemSelector: ".masonry-grid-item",
                            columnWidth: ".masonry-grid-item",
                            percentPosition: true,
                            horizontalOrder: true,
                        });
                    });
                }, 1000);
                setTimeout(() => {
                    AOS.init({
                        once: true,
                        offset: offsetY,
                        duration: 500,
                        throttleDelay : 100,
                        easing : 'ease-out',
                        anchorPlacement : 'top-center',
                    });
                }, 1100);
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.masonry-grid {
    margin: calc(var(--grid-gutter) * -1);
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}
.masonry-grid-item {
    width: 50%;
    padding: var(--grid-gutter);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .masonry-grid-item {
        width: 33.3333%;
    }
}
@media (min-width: 1024px) {
    .masonry-grid {
        margin: calc(var(--grid-gutter-lg) * -1);
    }
    .masonry-grid-item {
        padding: var(--grid-gutter-lg);
    }
}
@media (min-width: 1200px) {
}
</style>