<template>
    <v-btn v-bind="$attrs" color="primary" outlined icon class="u-btn-custom" @click="$emit('click')">
        <icon-rounded-object/>
        <div class="u-btn-custom__inner">
            <div class="font-size-14 font-size-md-16 font-weight-bold font-secondary">{{title}}</div>
            <icon-arrow-primary class="mt-4px mt-md-8px"/>
        </div>
    </v-btn>
</template>

<script>
import IconRoundedObject from "@/components/publish/styles/icons/icon-rounded-object.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
export default {
    props: {
        title: { type: String, default: "View More" },
    },
    components: {
        IconRoundedObject,
        IconArrowPrimary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.u-btn-custom{
    &.v-btn.v-btn--icon.v-size--default.v-btn--outlined{
        position: relative;
        border-color: transparent !important;
        width: 100px !important;
        height: 100px !important;
        ::v-deep{
            .v-btn__content{
                width: 100%;
                height: 100%;
                padding: 10px;
            }
        }
        &::before{
            display: none;
        }
    }
    &__inner{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 50%;
        transition: 0.15s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .u-btn-custom{
        &.v-btn.v-btn--icon.v-size--default.v-btn--outlined{
            width: 180px !important;
            height: 180px !important;
            ::v-deep{
                .v-btn__content{
                    padding: 20px;
                }
            }
        }
    }
}
@media (min-width:1024px){
    .u-btn-custom:hover{
        .u-btn-custom__inner{
            background-color: var(--v-primary-base);
            color: #fff !important;
            border-color: var(--v-primary-base) !important;
        }
    }
}
@media (min-width:1200px){
}

</style>
