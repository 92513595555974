var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', _vm._l(_vm.business, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "business-card",
      class: index % 2 == 0 ? 'ml-auto' : '',
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": item.aosDelay,
        "to": item.link,
        "max-width": "342",
        "rounded": ""
      }
    }, [_c('v-img', {
      staticClass: "business-card__image w-100",
      attrs: {
        "src": item.image,
        "aspect-ratio": 342 / 512
      }
    }), _c('div', {
      staticClass: "business-card__contents"
    }, [_c('div', {
      staticClass: "pa-16px pa-md-24px px-lg-34px py-lg-46px"
    }, [_c('tit', {
      staticClass: "white--text"
    }, [_vm._v(_vm._s(item.title))]), _c('txt', {
      staticClass: "txt--xl white--text mt-4px"
    }, [_vm._v(_vm._s(item.txt))])], 1)])], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }