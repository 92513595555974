import { render, staticRenderFns } from "./u-scroll-ani.vue?vue&type=template&id=1dbe957a&scoped=true&"
import script from "./u-scroll-ani.vue?vue&type=script&lang=js&"
export * from "./u-scroll-ani.vue?vue&type=script&lang=js&"
import style0 from "./u-scroll-ani.vue?vue&type=style&index=0&id=1dbe957a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dbe957a",
  null
  
)

export default component.exports