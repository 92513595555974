<template>
    <client-page class="app--main">
        <main-visual />

        <main-section class="main-section--business">
            <template #mainSectionImmersive>
                <!-- <v-img src="/images/main/business-bg2.svg" aspect-ratio="1840 / 480" class="business-bg business-bg--1 w-100" /> -->
                <v-img src="/images/main/business-bg3.svg" aspect-ratio="1840 / 480" class="business-bg business-bg--2 w-100" />
                <v-img src="/images/main/business-bg4.svg" aspect-ratio="1840 / 384" class="business-bg business-bg--3 w-100" />
            </template>            
            <tit-wrap-primary dark class="text-center">
                사업소개
                <template #TitAdd>Introduction</template>
            </tit-wrap-primary>
            <main-business />
        </main-section>

        <main-section class="main-section--value">            
            <tit-wrap-primary class="main-section--value__title">
                태성의 가치
                <template #TitAdd>Core Value</template>
            </tit-wrap-primary>
            <main-value />
        </main-section>

        <main-section class="main-section--introduction">            
            <tit-wrap-primary dark class="text-center">
                변호사 소개
                <template #TitAdd>Introduction</template>
            </tit-wrap-primary>
            <main-introduction />
        </main-section>

        <main-section class="main-section--project">    
            <u-tit-wrap-default> 
                <v-row no-gutters>
                    <v-col cols="12" md="">
                        <tit-wrap-primary class="pb-md-0">
                            주요 프로젝트
                            <template #TitAdd>Project</template>
                        </tit-wrap-primary>
                    </v-col>
                    <!-- <v-col cols="12" md="auto" class="d-flex d-md-block justify-end">
                        <btn-secondary to="/project" data-aos="zoom-out" data-aos-delay="150" />
                    </v-col> -->
                </v-row>     
            </u-tit-wrap-default>  
            <main-project />
        </main-section>

        <main-section class="main-section--stroy">
            <template #mainSectionImmersive>
                <v-img src="/images/main/stroy-bg2.svg" aspect-ratio="1840 / 480" class="stroy-bg stroy-bg--1 w-100" />
            </template>
            <v-row>
                <v-col cols="12" md="4">
                    <tit-wrap-primary dark>
                        태성소식
                        <template #TitAdd>Story</template>
                    </tit-wrap-primary>
                    <div class="d-flex d-md-block justify-end">
                        <btn-secondary to="/story" data-aos="zoom-out" data-aos-delay="150" />
                    </div>
                </v-col>
                <v-col cols="12" md="8">
                    <main-story />
                </v-col>
            </v-row>
        </main-section>

        <main-section class="main-section--contact">            
            <tit-wrap-primary dark class="text-center">
                오시는 길
                <template #TitAdd>Contact Us</template>
            </tit-wrap-primary>
            <main-contact />
        </main-section>

        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import MainSection from "@/sets/styles/mains/main-section.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainBusiness from "@/components/client/main/main-business.vue";
import MainValue from "@/components/client/main/main-value.vue";
import MainIntroduction from "@/components/client/main/main-introduction.vue";
import MainProject from "@/components/client/main/main-project.vue";
import MainStory from "@/components/client/main/main-story.vue";
import MainContact from "@/components/client/main/main-contact.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";

export default {
    components: {
        ClientPage,
        MainSection,
        MainVisual,
        MainBusiness,
        MainValue,
        MainIntroduction,
        MainProject,
        MainStory,
        MainContact,
        PopupLayer,

        TitWrapPrimary,
        UTitWrapDefault,
        BtnPrimary,
        BtnSecondary,
    },
    props: {},
    data() {
        return {};
    },
    mounted() {
        var Header = document.querySelector("header");
        Header.classList.add("header--light");

        var mainSectionValue = document.querySelector(".main-section--value");
        var mainSectionValueTop = mainSectionValue.offsetTop;

        var windowHeight = window.innerHeight || document.documentElement.clientHeight;

        function updateHeaderClass() {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > mainSectionValueTop - windowHeight) {
                Header.classList.remove("header--light");
            } else {
                Header.classList.add("header--light");
            }
        }

        window.addEventListener("scroll", updateHeaderClass);

        updateHeaderClass();
    },
    destroyed() {
        var Header = document.querySelector("header");
        Header.classList.remove("header--light");
        window.removeEventListener("scroll", this.updateHeaderClass);
    },

};
</script>

<style lang="scss" scoped>
.main-section{
    &--business{
        background-image: url(/images/main/business-bg.jpg);
        .business-bg{
            position: absolute;
            left: 0;
            &--1{
                top: 0;
            }
            &--2{
                top: 0;
            }
            &--3{
                bottom: 0;
            }
        }
    }
    &--value{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 60%;
            background-color: var(--v-grey-lighten5);
            top: 0;
            left: 0;
        }
        .container{
            position: relative;
        }
    }
    &--introduction{
        background-image: url(/images/main/introduction-bg.jpg);
    }
    &--stroy{
        background-image: url(/images/main/stroy-bg.jpg);
        .stroy-bg{
            position: absolute;
            left: 0;
            &--1{
                bottom: 0;
            }
        }
    }
    &--contact{
        overflow: hidden;
        background-image: url(/images/main/contact-bg.jpg);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .main-section{
        &--value{
            &::before{
                height: 596px;
            }
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .main-section{
        &--value{
            &__title{
                position: absolute;
                left: 12px;
                top: 0;
                z-index: 2;
            }
        }
    }
}

</style>
