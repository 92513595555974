var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header header--fixed"
  }, [_c('div', {
    staticClass: "header__body"
  }, [_c('div', {
    staticClass: "header__inner--pc"
  }, [_c('btn-hamburger-primary', {
    staticClass: "btn-hamburger--pc"
  }), _c('div', {
    staticClass: "header__right-menu"
  }, [_c('v-row', {
    staticClass: "flex-nowrap",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "to": "/guide",
      "text": "",
      "color": "grey lighten-1"
    }
  }, [_vm._v("오시는 길")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "href": "http://pf.kakao.com/_FUQwK/chat",
      "target": "_blank",
      "text": "",
      "color": "grey lighten-1"
    }
  }, [_vm._v("카카오톡")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit font-secondary",
    attrs: {
      "href": "tel:1661871",
      "target": "_blank",
      "text": "",
      "color": "grey lighten-1"
    }
  }, [_vm._v("1661-8731")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "header__inner--mobile"
  }, [_c('v-container', [_c('h1', {
    staticClass: "header__logo"
  }, [_c('a', {
    attrs: {
      "href": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("법무법인 태성")])])]), _c('div', {
    staticClass: "header__contents"
  }, [_c('div', {
    staticClass: "header__contents-wrap"
  }, [_c('v-row', {
    staticClass: "header__mobile-link-group",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "header__mobile-link-wrap",
    attrs: {
      "cols": "6"
    }
  }, [_c('a', {
    staticClass: "header__mobile-link",
    attrs: {
      "href": "http://pf.kakao.com/_FUQwK/chat",
      "target": "_blank"
    }
  }, [_c('icon-kakao'), _c('p', {
    staticClass: "header__mobile-link-title"
  }, [_vm._v("카카오톡")])], 1)]), _c('v-col', {
    staticClass: "header__mobile-link-wrap",
    attrs: {
      "cols": "6"
    }
  }, [_c('a', {
    staticClass: "header__mobile-link",
    attrs: {
      "href": "tel:1661871",
      "target": "_blank"
    }
  }, [_c('icon-tel'), _c('p', {
    staticClass: "header__mobile-link-title"
  }, [_vm._v("1661-8731")])], 1)])], 1), _c('div', {
    staticClass: "header__gnb d-block d-xl-flex justify-center"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1)], 1)])])], 1)]), _c('btn-hamburger-primary', {
    staticClass: "btn-hamburger--mobile"
  }), _c('div', {
    staticClass: "side-gnb-wrap"
  }, [_c('v-img', {
    staticClass: "slide-gnb__bg slide-gnb__bg--1 w-100",
    attrs: {
      "src": "/images/common/slide-gnb-bg1.svg",
      "max-width": "1020",
      "aspect-ratio": 1020 / 302
    }
  }), _c('v-img', {
    staticClass: "slide-gnb__bg slide-gnb__bg--2 w-100",
    attrs: {
      "src": "/images/common/slide-gnb-bg2.svg",
      "max-width": "986",
      "aspect-ratio": 986 / 302
    }
  }), _c('v-container', [_c('v-row', [_c('v-col', [_c('client-gnb', {
    attrs: {
      "className": "slide-gnb"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-img', {
    staticClass: "slide-gnb__img",
    attrs: {
      "src": "/images/common/slide-gnb-img.png",
      "max-width": "586",
      "aspect-ratio": 586 / 470
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }