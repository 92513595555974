<template>
    <div data-aos="fade-up" data-aos-delay="100" class="value-contents">
        <icon-rounded-object color="grey--text text--lighten-1" class="d-none d-md-block"/>
        <div class="value-contents__btn-group mb-20px mb-md-0">
            <v-row align="center">
                <v-col cols="auto">
                    <btn-arrow-primary prev color="grey lighten-1" class="swiper-btn-prev" />
                </v-col>
                <v-col cols="auto">
                    <span class="line"></span>
                </v-col>
                <v-col cols="auto">
                    <btn-arrow-primary next color="grey lighten-1" class="swiper-btn-next" />
                </v-col>
            </v-row>
        </div>        
        <swiper ref="swiperValue" :options="swiperOptionValue">
            <swiper-slide v-for="(item, index) in slide" :key="index" class="swiper swiper--value">
                <div class="value-contents__slide w-100">
                    <v-row>
                        <v-col cols="12" md="8" offset-md="4" class="value-contents__right">  
                            <div class="value-contents__right__inner">
                                <div class="value-contents__right__image-wrap">
                                    <div class="value-contents__right__image">
                                        <div class="value-contents__right__image__inner image-card" :style="'background-image: url('+item.image+')'"></div>
                                    </div>
                                </div> 
                            </div>
                        </v-col>
                        <v-col cols="12" md="10" lg="7" class="value-contents__left">            
                            <div class="value-contents__left__inner">
                                <v-card rounded dark color="grey darken-3" class="value-contents__left__card">
                                    <div class="pa-20px pa-md-30px pa-lg-60px">
                                        <txt class="white--text font-weight-bold line-height-1 font-secondary">
                                            Value 0{{index+1}}
                                        </txt>
                                        <span class="line my-12px my-md-16px"></span>
                                        <tit class="white--text">
                                            <span v-html="item.txt"></span>
                                        </tit>
                                    </div>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>   
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import IconRoundedObject from "@/components/publish/styles/icons/icon-rounded-object.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,

        BtnArrowPrimary,
        IconRoundedObject,
        Tit,
        Txt,
    },
    data() {
        return {
            swiperOptionValue: {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 12,
                speed: 500,
                lazy: {
                  loadPrevNext: true,
                  loadPrevNextAmount: 10
                },
                loopedSlides: 10,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".value-contents__btn-group .swiper-btn-next",
                    prevEl: ".value-contents__btn-group .swiper-btn-prev",
                },
                breakpoints: {
                    1544:{
                        // slidesPerView: 2,
                        spaceBetween: 60,
                    },
                },
            },
            slide : [
                {
                    txt : "기술을 선도하는 Lawyer <br />법률을 활용하는 Professtional Engineer ",
                    image : "/images/main/value-img.jpg",
                },
                {
                    txt : "새로운 생각이 <br class='d-none d-lg-block'/>한차원 높은 가치를 창출합니다. ",
                    image : "/images/main/value-img2.jpg",
                },
                {
                    txt : "길을 만들어 가는 로펌, <br class='d-none d-lg-block'/>법무법인 태성입니다. ",
                    image : "/images/main/value-img3.jpg",
                },
            ]
        };
    },
}
</script>

<style lang="scss" scoped>
.value-contents{
    position: relative;
    z-index: 1;
    .icon-rounded-object{
        top: -90px;
        left: calc(33.33333% - 78px);
        z-index: 5;
    }
    &__slide{
        position: relative;
    }
    &__left{
        margin-top: -123px;
        &__inner{
            padding: 0 16px;
            width: 100%;
            max-width: 100%;
        }
        .line{
            display: block;
            width: 1px;
            height: 28px;
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
    &__right{
        &__inner{
            position: relative;
        }
        &__image{
            position: relative;
            width: 100%;
            height: 0;
            padding-top: calc((634 / 952) * 100%);
            &-wrap{
                width: 100%;
            }
            &__inner{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
            }
        }
    }
    &__btn-group{
        .line{
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .value-contents{
        position: relative;
        &__left{
            margin-top: -154px;
            &__inner{
                padding: 0;
                width: 100%;
                max-width: 100%;
            }
            .line{
                height: 46px;
            }
        }
    }
}
@media (min-width:1024px){
    .value-contents{
        &__left{
            margin-top: 0;
            position: absolute;
            left: -12px;
            top: 0;
            z-index: 1;
            height: 100%;
            display: flex;
            align-items: flex-end;
            &__inner{
                padding-bottom: 40px;
            }
        }
        &__btn-group{
            position: absolute;
            left: 0;
            bottom: -12px;
            z-index: 2;
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .value-contents{
        &__left{
            display: block;
            &__inner{
                padding-top: 238px;
                padding-bottom: 0;
                min-height: 0;
                margin-top: 0;
            }
        }
    }
}
@media (min-width:1544px){
    .value-contents{
        ::v-deep{
            .swiper-container{
                overflow: visible;
            }
        }
        &__right{
            position: relative;
            left: -33.3333333333%;
            transition: 0.5s ease-out;
            &__inner{
                height: 634px;
                display: flex;
                align-items: flex-end;
            }
            &__image{
                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    opacity: 1;
                    visibility: visible;
                    transition: 0.5s ease-out;
                }
                &-wrap{
                    max-width: 600px;
                    max-height: 400px;
                    transition: 0.5s ease-out;
                }
            }
        }
        &__left{
            &__card{
                opacity: 0;
                visibility: hidden;
                transition: 0.25s ease-out;
            }
        }
        .swiper-slide-active{
            .value-contents__left{
                &__card{
                    opacity: 1;
                    visibility: visible;
                    transition: 0.25s 0.5s ease-out;
                }
            }
            .value-contents__right{
                left: 0;
            }
            .value-contents__right__image::after{
                opacity: 0;
                visibility: hidden;
            }
            .value-contents__right__image-wrap{
                max-width: 100%;
                max-height: 100%;                
            }
        }
    }
}

</style>