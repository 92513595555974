var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    ref: "swiperStroy",
    staticClass: "swiper swiper--stroy stroy-slide",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "options": _vm.swiperOptionsStory
    }
  }, _vm._l(_vm.boards, function (board) {
    return _c('swiper-slide', {
      key: board._id
    }, [_c('story-item', {
      attrs: {
        "to": `/story/${board === null || board === void 0 ? void 0 : board._id}`,
        "image": board === null || board === void 0 ? void 0 : board.thumb
      },
      scopedSlots: _vm._u([{
        key: "TxtAdd",
        fn: function () {
          return [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.summary))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }