<template>
    <swiper data-aos="fade-up" data-aos-delay="100" class="swiper swiper--stroy stroy-slide" ref="swiperStroy" :options="swiperOptionsStory">

        <!-- S: 태성소식 -->
        <swiper-slide v-for="board in boards" :key="board._id">
            <story-item :to="`/story/${board?._id}`" :image="board?.thumb">
                {{ board?.subject }}
                <template #TxtAdd>{{ board?.summary }}</template>
            </story-item>
        </swiper-slide>
        <!-- E: 태성소식 -->
        
    </swiper>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import StoryItem from "@/components/client/story/story-item.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,
        StoryItem,
        BtnPrimary,
        Tit,
        Txt,
    },
    async mounted() {
        await this.init();
    },
    data: () => {
        return {
            boards: [],
            filter: {
                code: "news",
            },
            swiperOptionsStory: {
                allowTouchMove: true,
                slidesPerView: 2,
                slidesPerColumn: 2,
                slidesPerColumnFill: "row",
                spaceBetween: 10,
                speed: 500,
                centeredSlides: false,
                loop: false,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 60,
                    },
                    1544: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                },
            },
        }
    },
    methods: {
        async init() {
            await this.saerch();
        },
        async saerch() {
            let { boards } = await api.v1.boards.gets({
                params: this.filter,
            });
            this.boards = boards;
        }
    }
}
</script>

<style lang="scss" scoped>
@media (min-width:1544px){
    .stroy-slide.swiper-container{
        width: 1458px;
    }
}
</style>